<template>
  <CCard v-if="show">
    <CCardHeader><h4>{{ $t("my_payment_method") }} <span v-if="this.$store.state.user.abos[0].pivot.moyen_paiement_a_jour == 0"><CBadge color="danger">{{ $t("merci_mettre_a_jour") }}</CBadge></span></h4></CCardHeader>
    <CCardBody>
      <p>{{ $t("cb") }} : {{ card.brand }} **** **** **** {{ card.last4 }}</p>
      <p>{{ $t("exp_month") }} : {{ card.exp_month }}</p>
      <p>{{ $t("exp_year") }} : {{ card.exp_year }}</p>

      <button class="btn btn-primary" @click="updatePayment">
        update payment
      </button>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "PaymentMethod",
  data() {
    return {
      card: Object,
      show: true
    };
  },
  created() {
    let self = this;
    window.axios
      .get("/api/stripegetuserpayment")
      .then((res) => {
        self.card = res.data.rep;
      })
      .catch((err) => {
        console.log("error", err);
        self.show = false
      });
  },
  methods: {
    async updatePayment() {
      let loader = this.$loading.show({ canCancel: false });
      try {
        const rep = await window.axios.get("/api/stripeupdatepayment");
        window.open(rep.data.rep.url, "_self");
      } catch (err2) {
        console.log("FAILURE 3!!", err2);
        loader.hide();
      }
    },
  },
};
</script>

<style scoped>
.warning_msg{
  color:red;
}
</style>

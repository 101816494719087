<template>
  <div class="account">
    <CRow >
      <CCol sm>
        
        <payment-method></payment-method>
      </CCol>
    </CRow>
    <CRow>

      <CCol v-for="abo in abos" :key="abo.id" sm="12" md="4" xl="4" class="mb-3 col-flex" >
        <CCard style="width:100%; max-width: 735px;">
          <CCardHeader>
            <h6>{{ $t(abo.type) }}</h6>
            <div class="block-prix">
              <div class="prix">
                <span v-if="abo.type == 'free'">0</span>
                <span v-else-if="abo.type == 'medium'">29</span>
                <span v-else>49</span>
                €</div>
              <div class="month">/ {{ $t("month") }}</div>
            </div>
          </CCardHeader>
          <CCardBody>
            <ul v-if="abo.type == 'free'">
              <li>{{ $t("acces_1_ecran") }}</li>
              <li>{{ $t("3_photos") }}</li>
              <li>{{ $t("uniquement_dispo") }}</li>
              <li>{{ $t("pas_programmation") }}</li>
            </ul>
            <ul v-else-if="abo.type == 'medium'">
              <li>{{ $t("all_functions") }}</li>
              <li>{{ $t("possibilite_creer_ecrans") }}</li>
              <li>{{ $t("meida_ill") }}</li>
              <li>{{ $t("dispo_androidtv") }}</li>
              <li>{{ $t("poss_rasbery") }}</li>
              <li>{{ $t("en_option") }}</li>
            </ul>
            <ul v-else>
              <li>{{ $t("all_functions") }}</li>
              <li>{{ $t("possibilite_creer_ecrans") }}</li>
              <li>{{ $t("meida_ill") }}</li>
              <li>{{ $t("dispo_androidtv") }}</li>
              <li>{{ $t("poss_rasbery") }}</li>
              <li>{{ $t("en_option") }}</li>
              <li>{{ $t("interface_perso") }}</li> 
            </ul>

          </CCardBody>

          <CCardFooter align="center" v-if="(abo_user.type == 'free' && abo.type == 'free') || (abo_user.type != 'full' && abo.type == 'medium') || (abo_user.type == 'full' && abo.type == 'full') || (abo_user.type != 'full' && abo.type == 'full')">
            
            <div v-if="(abo_user.type == abo.type) " class="text-blue">{{ $t("offre_actuelle") }}</div>
            <span v-if="abo.type != 'free' && abo_user.type != abo.type">
               <button class="btn btn-primary" @click="buy(abo.id)">{{ $t("subscribe") }}</button>
            </span>
            <span v-if="abo.type != 'free' && abo_user.type == abo.type">
               <button class="btn btn-primary" @click="cancel()">{{ $t("cancel_sub") }}</button>
              <CAlert v-if="errorCancel" color="danger">{{errorCancel}}</CAlert>
            </span>
          </CCardFooter>
        </CCard>
      </CCol>
      
    </CRow>
    <!--router-link to="/dashboard/about">About</router-link-->
  </div>
</template>

<script>
import PaymentMethod from '../components/account/PaymentMethod.vue';
export default {
  name: "Account",
  components: {
    PaymentMethod,
  },
  data() {
    return {
      errors: {},
      loading: false,
      session_stripe_id: null,
      abos: null,
      errorCancel: null, 
      abo_user: {}
    };
  },
  methods: {
    async updateData() {
      let response = await window.axios.put("/api/update", this.form);
      this.$store.commit("setAuth", response.data.user);
      console.log("up", this.$store.state.user);
    },
    async logout() {
      try {
        let r = await window.axios.post("/logout");
        console.log("logout", r);
        this.$store.commit("unAuth");
      } catch (error) {
        console.log(error);
      }
    },
    async buy(id_abo){
      let loader = this.$loading.show({canCancel: false,});
      try{
        const rep = await window.axios.get('/api/stripesession/'+id_abo)
        //rep.data.media
        //this.session_stripe_id = rep.data.rep.id
        window.open(rep.data.rep.url,"_self")
      }catch (err2) {
        console.log("FAILURE 2!!", err2);
        loader.hide()
      }
    },
    async cancel(){
      this.$confirm(
        {
          message: `Are you sure? Vos écrans seront supprimés.`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              // ... do something
              let loader = this.$loading.show({canCancel: false,});
              try{
                await window.axios.put('/api/cancelsubscription')
                loader.hide()
                window.location.reload()
                //TODO : relod + message
              }catch (err2) {
                console.log("FAILURE 2!!", err2);
                loader.hide()
                this.errorCancel = 'error_cancel_subscription'
              }
            }
          }
        }
      )
      
    }
  },
  mounted: function () {
    if (this.$store.state.user.abos[0])
      // si authentifié
      this.abo_user = this.$store.state.user.abos[0];
  },
  async created() {
    const rep = await window.axios.get('/api/allabo')
    this.abos = rep.data.data
    //this.$i18n.locale = 'fr'
  },
};
</script>

<style scoped>
h6{
  color:#f45a2a;
  font-weight: bold;
}

.block-prix{
  display: flex;
  align-items: center;
}

.prix{
  font-size:42px;
  font-weight: bold;
  margin-right: 5px;
}

li{
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
  
}

.col-flex{
  display: flex;
}
.text-blue{
  padding:7px;
}
</style>
